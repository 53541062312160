import { getStock, exportStock, getStockNum } from "./api.js";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/inventorySnapshot",
          name: "inventorySnapshot",
          title: "库存快照"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        x: "2600"
        // y: 400
      },
      columns: [
        {
          label: "销售组织",
          prop: "orgName",
          ellipsis: true,
          key: "orgName",
          width: 110
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          ellipsis: true,
          key: "materialGroupName",
          width: 90
        },
        {
          label: "产品型号",
          prop: "productModelStr",
          ellipsis: true,
          key: "productModelStr",
          width: 200
        },
        {
          label: "颜色",
          prop: "productColor",
          ellipsis: true,
          key: "productColor",
          width: 90
        },
        {
          label: "总数量",
          prop: "totalQuantity",
          ellipsis: true,
          align: "right",
          key: "totalQuantity",
          width: 90
        },
        {
          label: "库存类别",
          prop: "stockTypeStr",
          ellipsis: true,
          align: "right",
          key: "stockTypeStr",
          width: 150
        },
        {
          label: "质量等级",
          prop: "qualityLevel",
          ellipsis: true,
          key: "qualityLevel",
          width: 150
        },
        {
          label: "产品阶段",
          prop: "productStage",
          ellipsis: true,
          key: "productStage",
          width: 150
        },
        {
          label: "三包库存数量",
          prop: "threePackageStockAmount",
          ellipsis: true,
          align: "right",
          key: "threePackageStockAmount",
          width: 300
        },
        {
          label: "样机数量",
          prop: "sampleStockAmount",
          ellipsis: true,
          align: "right",
          key: "sampleStockAmount",
          width: 150
        },
        {
          label: "正品库存数量",
          prop: "qualityGoodsStockAmount",
          ellipsis: true,
          align: "right",
          key: "qualityGoodsStockAmount",
          width: 250
        },
        {
          label: "在途数量",
          prop: "stockInTransitAmount",
          ellipsis: true,
          align: "right",
          key: "stockInTransitAmount",
          width: 150
        },
        {
          label: "待提数量",
          prop: "readyPickUpAmount",
          ellipsis: true,
          align: "right",
          key: "readyPickUpAmount",
          width: 150
        },
        {
          label: "库存日期",
          prop: "stockDateStr",
          ellipsis: true,
          key: "stockDateStr",
          width: 200
        },
        {
          label: "物料号",
          prop: "materialCode",
          ellipsis: true,
          key: "materialCode",
          width: 200
        },
        {
          label: "仓库",
          prop: "wareHouseName",
          ellipsis: true,
          key: "wareHouseName",
          width: 90
        },
        {
          label: "补差类型",
          prop: "compensationTypeStr",
          ellipsis: true,
          key: "compensationTypeStr",
          width: 150
        },

      ],
      data: [],
      terms: {},
      showLoading:false,
      totalQuantity:0
    };
  },
  created() {
  },
  methods: {
    //获取库存总数
    getTotalNum() {
      const data = {
        ...this.terms,
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
      };
      getStockNum(data).then(res=>{
        if(res.data.code == 0){
          this.totalQuantity = res.data.data || 0
        }else{
          this.totalQuantity = 0
          this.$message.warning(res.data.msg)
        }
      }).catch(err=>{
        this.totalQuantity = 0

      });
    },
    searchFun(arg) {
      this.terms = arg[0];
      this.pager.pageNo = 1;
      this.getData();
      this.getTotalNum()
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        ...this.terms,
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'库存快照.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        ...this.terms,
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
      };
      this.showLoading = true
      getStock(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.data = data.list || [];
        this.showLoading = false
        this.pager.count = data.totalRows;
      });
    }
  }
};
