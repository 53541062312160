/*
 * @Author: your name
 * @Date: 2022-01-10 18:22:53
 * @LastEditTime: 2022-01-10 18:27:14
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\stockCenter\inventorySnapshot\api.js
 */
import http from "@/utils/request";

//库存快照
export function getStock(data) {
    return http({
        method: "get",
        url: "/fast/cust/baseGicInventoryTmp/page.nd",
        params: data
    });
}
export function exportStock(data){
    return http({
        url:'/fast/cust/baseGicInventoryTmp/export.nd',
        method:'post',
        responseType: 'blob',
        data
    })
}
//库存快照总数
export function getStockNum(data) {
    return http({
        method: "post",
        url: "/baseGicInventoryTmp/findInventoryAmount.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data,
    });
}